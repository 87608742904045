<template lang="pug">
div(class='list-filter-input')
  control-input(:id='inputId' icon='search' grey :placeholder='placeholder' :modelValue='value' rounded v-bind='$attrs' @submit='submit' @clear='() => submit("")')
</template>

<script lang="ts">
import { useListFilter } from './'
import { defineComponent, type SetupContext } from 'vue'

const ListFilterInput = defineComponent({
  props: {
    placeholder: { type: String },
    inputId: { type: String },
    name: { type: String, default: 'q' },
    modelValue: String,
  },
  setup(props, context: SetupContext) {
    const { value, setProperty } = useListFilter(props.name, props.modelValue || '')
    if (props.modelValue !== undefined) {
      setProperty?.(props.name, props.modelValue)
    }
    return {
      value,
      submit: (value: string) => {
        setProperty?.(props.name, value)
        context.emit('search', value)
        context.emit('update:modelValue', value)
      },
    }
  },
})
export default ListFilterInput
</script>
